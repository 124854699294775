//新增病例-门诊病例保存
import { computed, onMounted, reactive, watch } from "@vue/composition-api";
import { ApiChiefComplaintSave } from "@/apis/index";
import { Base64 } from "js-base64";
import { DirectoryEnum } from "@/constants";
import { uploadFile } from "@/utils/alioss";
interface Module {
  basicInformationForm: object;
}
function chiefComplaintModuleFun(ins: any, basicInformationModule: any) {
  const chiefComplaintModule: any = reactive({
    chiefComplaintId: null,
    // chiefComplaintId: null || 6152,
    loading: false,
    enclosures: [],
    chiefComplaintForm: {
      trmtchief_id: "",
      user_id: "",
      trmtchief_date: "",
      trmtchief_doctor: "",
      trmtchief_type: "门诊治疗",
      trmtchief_zhusu: "",
      trmtchief_detail: "",
      trmtchief_yizhu: "",
      trmtchief_note: "",
      resource_path: "",
    },
    chiefComplaintRules: {
      trmtchief_date: [
        { required: true, message: "请选择就诊时间", trigger: "blur" },
      ],
      trmtchief_zhusu: [
        { required: true, message: "请输入主诉", trigger: "blur" },
      ],
    },

    //上传文件限制个数
    handleExceed(files: any, fileList: any) {
      // chiefComplaintModule.$message.warning(`当前限制选择 10 个文件`);
    },

    //根据插槽的file对象，拿到文件名，获取后缀来判断使用img还是video，因为插槽的file对象没有type
    showVideo(file: any) {
      if (file) {
        const res = file.split(".");
        const video = res.some((item: any) => {
          return item == "mp4";
        });
        const videos = res.some((item: any) => {
          return item == "mov";
        });
        if (video) {
          return "视频";
        } else if (videos) {
          return "视频";
        } else {
          return "照片";
        }
      }
    },

    //在线预览视频
    videoClick(url: any) {
      window.open(
        `https://pins-gateway.pinsdev.com/kkfileview/onlinePreview?url=${encodeURIComponent(
          Base64.encode(url)
        )}`
      );
    },
    //在线图片
    imgClick(url: any) {
      window.open(
        `https://pins-gateway.pinsdev.com/kkfileview/onlinePreview?url=${encodeURIComponent(
          Base64.encode(url)
        )}`
      );
    },

    // 上传进度
    handleProgress(event: any, file: any, fileList: any) {
      // console.log(1);
    },

    // 上传图片前模块
    beforeAvatarUpload(file: any) {
      if (!ins.canUpload) {
        ins.$message.warning("请先填写基础信息");
        return false;
      } else {
        const isCan = file.size / 1024 / 1024 < 100;
        if (isCan) {
          return new Promise((resolve, reject) => {
            const isCan = file.size / 1024 / 1024 < 100;
            const isVideo = file.type == "video/mp4";
            const isJEPG = file.type === "image/jpeg";
            const isJPG = file.type === "image/jpg";
            const fileSize = file.size / 1024; //换成kb
            let bool = false;
            if (isCan) {
              if (isJPG || isJEPG || isVideo) {
                //判断是否符合格式要求
                bool = true;
              } else {
                return Promise.reject;
              }
              if (bool) {
                if (isVideo) {
                  return resolve(file);
                } else {
                  //如果格式符合要求，但是size过大，对图片进行处理
                  const image = new Image();
                  let resultBlob: any = "";
                  image.src = URL.createObjectURL(file);
                  image.onload = () => {
                    resultBlob = chiefComplaintModule.compressUpload(
                      image,
                      fileSize,
                      200
                    ); //Blob
                    resolve(resultBlob);
                  };
                }
              } else {
                return Promise.reject;
              }
            } else if (!ins.canUpload) {
              ins.$message.warning("请先填写基本信息");
            } else {
              ins.$message.warning("超出文件大小！");
              return false;
            }
          });
        } else {
          ins.$message.warning("超出文件大小");
          return false;
        }
      }
    },

    // 压缩图片
    compressUpload(image: any, fileSize: number, ratio: number): any {
      const canvas = document.createElement("canvas"); //创建画布元素
      const ctx = canvas.getContext("2d");
      const width = image.width;
      const height = image.height;
      canvas.width = width;
      canvas.height = height;
      let compressRatio: any;
      if (fileSize < 200) {
        compressRatio = 1;
      } else {
        compressRatio = ratio / fileSize;
      }

      if (ctx === null) {
        ins.$message.error("画布创建失败");
      } else {
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(image, 0, 0, width, height);
        const compressData = canvas.toDataURL("image/jpeg", compressRatio); //等比压缩
        const blobImg = chiefComplaintModule.dataURItoBlob(compressData); //base64转Blob
        if (blobImg.size > 200 * 1024 && compressRatio < 1) {
          return chiefComplaintModule.compressUpload(
            image,
            fileSize,
            ratio - 30
          );
        }
        return blobImg;
      }
    },

    dataURItoBlob(data: any) {
      let byteString;
      if (data.split(",")[0].indexOf("base64") >= 0) {
        byteString = atob(data.split(",")[1]); //转二进制
      } else {
        byteString = unescape(data.split(",")[1]);
      }
      const mimeString = data.split(",")[0].split(":")[1].split(";")[0];
      const ia = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i += 1) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ia], { type: mimeString });
    },

    // 移除图片
    imgHandleRemove(file: any) {
      chiefComplaintModule.enclosures = chiefComplaintModule.enclosures.filter(
        (item: any) => {
          return item.url != file;
        }
      );
      const uploadImg = ins.$refs["upload-img"] as any;
      uploadImg.handleRemove(file);
    },

    // 上传附件--图片-----问题分析模块
    async uploadHttpRequestPicture(params: any) {
      const file = params.file;
      const fileName = file.name;
      chiefComplaintModule.uploadHttpRequest(params);
    },
    // 上传 给上传图片和文件的函数使用
    async uploadAttachment(file: File) {
      //upload_dir: `picture/${
      const fileName = new Date().getTime().toString();
      const fileExt = file.name.split(".").pop();

      const res = await uploadFile(
        DirectoryEnum.PICTRUE,
        file,
        fileName + "." + fileExt,
        `/${
          JSON.parse(localStorage.getItem("doctorLoginInfo") as any).id
        }/${localStorage.getItem("database_id")}`
      );

      return res;
    },
    // 接收具体路径path的返回值；
    async uploadHttpRequest(params: any) {
      const file = params.file;
      const path = (await this.uploadAttachment(file)) as any;
      if (path) {
        // ... 处理具体的业务逻辑; 调用api等...
        if (!chiefComplaintModule.loading) {
          chiefComplaintModule.enclosures.push({
            url: path,
          });
        }
      }
    },

    //获取文件后缀名
    getExtension(name: string) {
      return name.substring(name.lastIndexOf("."));
    },

    saveChiefComplaint() {
      if (!basicInformationModule.caseId) {
        ins.$message.warning("请先填写基本信息！");
        return;
      } else {
        chiefComplaintModule.chiefComplaintForm.user_id =
          basicInformationModule.caseId;
      }
      const formRef = ins.$refs.chiefComplaintRuleForm as any;
      formRef.validate((valid: boolean) => {
        if (valid) {
          if (chiefComplaintModule.chiefComplaintId) {
            chiefComplaintModule.chiefComplaintForm.trmtchief_id =
              chiefComplaintModule.chiefComplaintId;
          }
          const param = {
            ...chiefComplaintModule.chiefComplaintForm,
          };
          chiefComplaintModule.enclosures = chiefComplaintModule.enclosures.map(
            (item: any) => {
              return { url: item.url };
            }
          );
          param.resource_path = JSON.stringify(chiefComplaintModule.enclosures);
          ApiChiefComplaintSave(param).then((res) => {
            if (res.success) {
              chiefComplaintModule.chiefComplaintId = res.data;
              ins.$message.success("保存成功！");
            }
          });
        }
      });
    },
    chiefComplaintRadio(property: string, content: string) {
      if (chiefComplaintModule.chiefComplaintForm[property] == content) {
        chiefComplaintModule.chiefComplaintForm[property] = "";
      } else {
        chiefComplaintModule.chiefComplaintForm[property] = content;
      }
    },
  });
  return {
    chiefComplaintModule,
  };
}
export { chiefComplaintModuleFun };
