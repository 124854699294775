//电子病例-基础信息
import { computed, onMounted, reactive, watch } from "@vue/composition-api";
import {
  ApiBasicInformationSave,
  ApiBasicInfoCheckName,
  ApiGetllnessList,
  ApiGetProvince,
  ApiGetCity,
  ApiGetArea,
} from "@/apis/index";
import { checkIdCard, getAgeFromIDCard } from "@/utils";
import * as utils from "@/utils";

interface Module {
  basicInformationForm: object;
}
function basicInformationModuleFun(ins: any) {
  const passportRule = [
    { required: false, message: "请输入证件号码", trigger: "blur" },
  ];
  const idCardRule = [
    { required: false, message: "请输入证件号码", trigger: "blur" },
    {
      pattern:
        /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/,
      message: "请输入正确的身份证号",
      trigger: "blur",
    },
  ];
  const basicInformationModule: any = reactive({
    caseId: null,
    // caseId: null || 20919,
    //现住地址级联选择器 表单内容 类型-列表
    currentAddr: [],
    //基础信息数据
    basicInformationForm: {
      user_id: "",
      user_name: "",
      user_sex: "",
      user_tel: "",
      user_idtype: "",
      user_idcardnum: "",
      user_birthday: "",
      user_education: "",
      user_height: "",
      user_weight: "",
      province_code: "",
      city_code: "",
      area_code: "",
      user_communicationaddr: "",
      disease_date: "",
      user_disease: "",
    },
    sexList: [
      {
        label: "男",
        value: "男",
      },
      {
        label: "女",
        value: "女",
      },
    ],
    documentsList: [
      {
        label: "身份证",
        value: "身份证",
      },
      {
        label: "护照",
        value: "护照",
      },
    ],
    educationLevellist: [
      "未知",
      "本科",
      "研究生",
      "大专",
      "中专",
      "技校",
      "高中",
      "初中",
      "小学",
      "文盲",
    ],
    currentIllnessList: [],
    currentAddressProps: {
      lazy: true,
      lazyLoad(node: any, resolve: any) {
        let result = [];
        let param = {};
        //获取当前层级
        const { level } = node;
        if (level == 0) {
          ApiGetProvince(param).then((res) => {
            if (res.success) {
              // code: "34"
              // first_letter: "A"
              // name: "安徽省"
              result = res.data.map((item: any) => {
                return {
                  value: item.code,
                  label: item.name,
                };
              });
              resolve(result);
            }
          });
        } else if (level == 1) {
          param = {
            province_code: node.value,
          };
          ApiGetCity(param).then((res) => {
            if (res.success) {
              // code: "3408"
              // first_letter: "A"
              // name: "安庆市"
              // province_code: "34"
              result = res.data.map((item: any) => {
                return {
                  value: item.code,
                  label: item.name,
                };
              });
              resolve(result);
            }
          });
        } else if (level == 2) {
          param = {
            city_code: node.value,
          };
          ApiGetArea(param).then((res) => {
            if (res.success) {
              // city_code: "3408"
              // code: "340871"
              // first_letter: "A"
              // name: "安徽安庆经济开发区"
              // province_code: "34"
              result = res.data.map((item: any) => {
                return {
                  value: item.code,
                  label: item.name,
                  leaf: level >= 2,
                };
              });
              resolve(result);
            }
          });
        }
      },
    },
    basicInformationRules: {
      user_name: [
        { required: true, message: "请输入患者姓名", trigger: "blur" },
      ],
      user_sex: [
        { required: true, message: "请选择患者性别", trigger: "blur" },
      ],
      user_tel: [
        { required: true, message: "请输入联系方式", trigger: "blur" },
      ],
      user_birthday: [
        { required: true, message: "请选择出生日期", trigger: "blur" },
      ],
      user_idcardnum: passportRule,
      disease_date: [
        { required: true, message: "请选择发病年份", trigger: "blur" },
      ],
      user_disease: [
        { required: true, message: "请选择当前所患疾病", trigger: "blur" },
      ],
    },
    saveBasicInformation() {
      const currentAddrList = basicInformationModule.currentAddr;
      if (currentAddrList.length == 3) {
        basicInformationModule.basicInformationForm.province_code =
          currentAddrList[0];
        basicInformationModule.basicInformationForm.city_code =
          currentAddrList[1];
        basicInformationModule.basicInformationForm.area_code =
          currentAddrList[2];
      } else {
        basicInformationModule.basicInformationForm.province_code = "";
        basicInformationModule.basicInformationForm.city_code = "";
        basicInformationModule.basicInformationForm.area_code = "";
      }
      const formRef = ins.$refs.basicInformationRuleForm as any;
      formRef.validate((valid: boolean) => {
        if (valid) {
          if (basicInformationModule.caseId) {
            basicInformationModule.basicInformationForm.user_id =
              basicInformationModule.caseId;
          }
          const param = {
            ...basicInformationModule.basicInformationForm,
          };
          param.age = Number(param.age);
          param.user_disease = JSON.stringify(
            basicInformationModule.basicInformationForm.user_disease
          );
          //如果有填写证件号码，需要判断证件类型，没有证件号码直接提交
          if (basicInformationModule.basicInformationForm.user_idcardnum) {
            //如果证件类型是护照，不进行验证,直接调用新增基础信息接口
            if (
              basicInformationModule.basicInformationForm.user_idtype == "护照"
            ) {
              const par = {
                doctor_id: (utils.get("doctorLoginInfo") as any).id,
                user_name:
                  basicInformationModule.basicInformationForm.user_name,
                user_id: basicInformationModule.basicInformationForm.user_id,
              };
              ApiBasicInfoCheckName(par).then((ress) => {
                if (!ress.data.duplication_name) {
                  ApiBasicInformationSave(param).then((res) => {
                    if (res.success) {
                      basicInformationModule.caseId = res.data;
                      ins.$message.success("保存成功！");
                    }
                  });
                } else {
                  ins
                    .$confirm("已有相同姓名的患者，确认保存吗？", "提示", {
                      confirmButtonText: "确定",
                      cancelButtonText: "取消",
                      type: "warning",
                    })
                    .then(async () => {
                      ApiBasicInformationSave(param).then((res) => {
                        if (res.success) {
                          basicInformationModule.caseId = res.data;
                          ins.$message.success("保存成功！");
                        }
                      });
                    });
                }
              });
            } else {
              //如果证件类型是身份证 校验身份证与姓名是否匹配
              checkIdCard(
                basicInformationModule.basicInformationForm.user_idcardnum,
                basicInformationModule.basicInformationForm.user_name,
                function (res: any) {
                  if (res != "01") {
                    ins.$message.warning(res);
                    return;
                  }
                  const par = {
                    doctor_id: (utils.get("doctorLoginInfo") as any).id,
                    user_name:
                      basicInformationModule.basicInformationForm.user_name,
                    user_id:
                      basicInformationModule.basicInformationForm.user_id,
                  };
                  ApiBasicInfoCheckName(par).then((ress) => {
                    if (!ress.data.duplication_name) {
                      ApiBasicInformationSave(param).then((res) => {
                        if (res.success) {
                          basicInformationModule.caseId = res.data;
                          ins.$message.success("保存成功！");
                        }
                      });
                    } else {
                      ins
                        .$confirm("已有相同姓名的患者，确认保存吗？", "提示", {
                          confirmButtonText: "确定",
                          cancelButtonText: "取消",
                          type: "warning",
                        })
                        .then(async () => {
                          ApiBasicInformationSave(param).then((res) => {
                            if (res.success) {
                              basicInformationModule.caseId = res.data;
                              ins.$message.success("保存成功！");
                            }
                          });
                        });
                    }
                  });
                }
              );
            }
          } else {
            const par = {
              doctor_id: (utils.get("doctorLoginInfo") as any).id,
              user_name: basicInformationModule.basicInformationForm.user_name,
              user_id: basicInformationModule.basicInformationForm.user_id,
            };
            ApiBasicInfoCheckName(par).then((ress) => {
              if (!ress.data.duplication_name) {
                ApiBasicInformationSave(param).then((res) => {
                  if (res.success) {
                    basicInformationModule.caseId = res.data;
                    localStorage.setItem("database_id", res.data);
                    ins.canUpload = true;
                    ins.$message.success("保存成功！");
                  }
                });
              } else {
                ins
                  .$confirm("已有相同姓名的患者，确认保存吗？", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                  })
                  .then(async () => {
                    ApiBasicInformationSave(param).then((res) => {
                      if (res.success) {
                        basicInformationModule.caseId = res.data;
                        localStorage.setItem("database_id", res.data);
                        ins.canUpload = true;
                        ins.$message.success("保存成功！");
                      }
                    });
                  });
              }
            });
          }
        }
      });
    },
    dealCard() {
      const idCardNum =
        basicInformationModule.basicInformationForm.user_idcardnum;
      if (
        idCardNum.length === 18 &&
        basicInformationModule.basicInformationForm.user_idtype === "身份证"
      ) {
        const age = getAgeFromIDCard(idCardNum);
        basicInformationModule.basicInformationForm.age = age;
      }
    },
    basicInformationSelectChange(content: string) {
      if (content == "身份证") {
        basicInformationModule.basicInformationRules.user_idcardnum =
          idCardRule;
      } else {
        basicInformationModule.basicInformationRules.user_idcardnum =
          passportRule;
      }
      basicInformationModule.basicInformationForm.user_idcardnum = "";
    },
    initCurrentIllnessList() {
      const param = {
        dc_typeid: 699,
      };
      ApiGetllnessList(param).then((res) => {
        if (res.success) {
          basicInformationModule.currentIllnessList = res.data;
        }
      });
    },
  });
  return {
    basicInformationModule,
  };
}
export { basicInformationModuleFun };
